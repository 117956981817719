import { Box, Card, Typography, Button, makeStyles } from '@material-ui/core'
import LaptopChromebookIcon from '@material-ui/icons/LaptopChromebook';
import LinearProgressWithLabel from '@material-ui/core/LinearProgress';
import { useNavigate } from 'react-router';
import useAuth from '../../../hooks/useAuth';
import Image from '../../Image'
import arrowIcon from '../../../assets/arrow.svg'
const useStyles = makeStyles((theme) => ({
  root: {},
  courseCard: {
    width: '350px',
    height: '400px',
    padding: '1rem',
    backgroundColor: 'white',
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  courseTitle: {
    fontWeight: 'bold',
    fontSize: '.9rem',
    color: theme.palette.text.primary,
    lineHeight: '1.2rem',
  },
  courseProgress: {
    fontSize: '.9rem',
    color: theme.palette.text.primary,
  },
  chevronIcon: {
    color: '#FFF',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '50%',
  },
  imageContainer: {
    height: 'auto',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'block'
  },
  card: {
    borderRadius: '0.5rem',
    boxShadow: '0 0 10px rgba(0,0,0,0.25)',
    padding: '1rem',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  button: {
    textTransform: 'uppercase',
    padding: '0.5rem 1.5rem',
    marginTop: '1rem',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
}))

const CourseCard = ({ course, progress }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { user } = useAuth()
  const hasAccess = user && (!user.subscriptionExpired || user.subtype === 'publisher')
  const coverBox = () => {
    return (
      <Box sx={{ backgroundColor: '#C1C1C1', width: '320px', height: '260px', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', margin: 'auto' }} >
          <LaptopChromebookIcon sx={{ fontSize: 100, color: '#FFF' }} />
        </Box>
      </Box>
    )
  }

  const courseLink = hasAccess ? `/yogarenew/courses/${course._id}/curriculum` : `/yogarenew/subscriptions`

  return (
    <Card key={course._id} className={classes.card} onClick={() => navigate(courseLink)}>
      <Box className={classes.imageContainer}>
        <Image formats={course.cover?.formats} alt={course.title} className={classes.image} />
      </Box>
      <Box className={classes.textContainer}>
        <Box style={{ overflow: "hidden", textOverflow: "ellipsis", width: '100%' }}>
          <Typography
            noWrap
            sx={{
              fontSize: '1rem',
              fontWeight: 600,
            }}
          >
            {course.title}
          </Typography>
        </Box>
        <img src={arrowIcon} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {!hasAccess ?
          <Button variant="contained" className={classes.button} onClick={() => navigate(courseLink)}>
            SUBSCRIBE TO ACCESS
          </Button> :
          (progress === null || progress === undefined) &&
          <Button variant="contained" className={classes.button} onClick={() => navigate(courseLink)}>
            START COURSE
          </Button>
        }
        {(hasAccess && progress !== null && progress !== undefined) && (
          <>
            <Box sx={{ mt: 2, width: '100%' }}>
              <Typography variant="subtitle2" sx={{ textAlign: 'center', my: 1 }}>
                {`${progress}% complete`}
              </Typography>
              <LinearProgressWithLabel variant="determinate" value={progress} />
            </Box>
          </>
        )}
      </Box>

    </Card>
  )
}

export default CourseCard