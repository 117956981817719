import { Box, Grid, Typography } from '@material-ui/core'
import CourseCard from '../../../../components/publisher-frontend/courses/CourseCard'

export const CoursesTab = ({ courses, navigate, coursesProgress }) => {

    return (
        <Box sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            px: 10,
            '@media (max-width: 960px)': {
                px: 2,
            },
        }}>
            <Grid container spacing={3} sx={{ py: 8, px: 5 }}>
                {courses && courses.length > 0 ?
                    courses
                        .filter(course => course.status === 'published')
                        .map((course, index) => {
                            let progress = coursesProgress?.find((item) => item.id === course._id)
                            if (!progress && coursesProgress) {
                                progress = { progress: 0 }
                            }
                            return (
                                <Grid item xs={12} sm={6} md={3} key={index}
                                    sx={{
                                        '&:hover': {
                                            cursor: 'pointer'
                                        }
                                    }}>
                                    <CourseCard course={course} progress={progress?.progress} />
                                </Grid>
                            )
                        })
                    :
                    <Typography variant="h6">No courses found for this instructor.</Typography>
                }
            </Grid>
        </Box>
    )
}
